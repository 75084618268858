<template>
  <div>
    <div
      class="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12"
    >
      <img
        src="./../img/beams.jpg"
        alt=""
        class="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        width="1308"
      />
      <div
        class="absolute inset-0 bg-main-123 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"
      ></div>
      <div
        class="relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg min-w-7xl max-w-6xl sm:rounded-lg sm:px-10"
      >
        <div class="mx-auto max-w-md">
          <div class="text-center">
            <img
              src="./../img/codehat-v@3x.png"
              class="m-auto h-[150px] self-center"
              alt="CODEHAT Private Limited"
            />
          </div>
          <div class="divide-y divide-gray-300/50">
            <div class="space-y-6 py-8 text-base leading-7 text-gray-600">
              <div class="hidden">
                <p>If you've reached here you may have searching for it.</p>
                <p>Well we're also finding talents to work with us.</p>
                <ul class="space-y-4">
                  <li class="flex items-center">
                    <svg
                      class="h-6 w-6 flex-none fill-sky-100 stroke-sky-500 stroke-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>

                    <a
                      class="ml-4"
                      href="mailto:career@inprog.codehat.in?subject=Want to work with you&body=Hi! Team CodeHat,"
                    >
                      mail us your CV on
                      <code class="text-sm font-bold text-gray-900"
                        >career@inprog.codehat.in
                      </code>
                    </a>
                  </li>
                </ul>
                <p>We may work together .</p>
              </div>

              <div class="p-6">
                <h1 class="text-2xl font-medium">
                  Are you looking for a challenging and rewarding internship experience in
                  the field of technology?
                </h1>
                <h3 class="text-xl font-medium">Look no further than CodeHat!</h3>
                <p class="my-4">
                  We are a dynamic and innovative company that is always on the lookout
                  for talented students to join our team as interns. As an intern at
                  CodeHat, you will have the opportunity to work on exciting projects,
                  learn from experienced professionals, and gain valuable industry
                  experience.
                </p>
                <p class="my-4">
                  Our internships are designed to provide students with a hands-on,
                  real-world experience in a fast-paced and challenging environment. You
                  will work alongside our talented team of developers, designers, and
                  engineers, and have the opportunity to contribute to the development of
                  cutting-edge technology projects.
                </p>
                <p class="my-4">
                  If you are a student who is passionate about technology, eager to learn,
                  and ready to take on new challenges, we want to hear from you! To apply
                  for an internship at CodeHat, please
                  <a
                    class="text-blue-500 hover:underline"
                    href="mailto:career@inprog.codehat.in?subject=Want to work with you&body=Hi! Team CodeHat,"
                    >email us</a
                  >
                  your CV and a brief cover letter detailing your qualifications and why
                  you would like to intern with us.
                </p>
                <p class="py-1 font-bold text-center">OR</p>

                <p class="my-4 text-center">
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfbCixOcdYXD_twFonOsRpscOSug9Z-Kv3qxCqr0MOw61XL9Q/viewform?usp=sf_link" title="Submit the form" target="_blank" class="bg-[#28BED2] px-3 py-2 rounded-md text-white">
                    Submit The Form
                  </a>
                </p>

                <p class="my-4">
                  Don't miss out on this opportunity to jump start your career in
                  technology! Apply to join the CodeHat team today.
                </p>

                <div class="py-4 text-center text-gray-400">- Team CodeHat</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import NavbarComponent from "../components/Navbar.vue";
// import FooterComponent from "../components/Footer.vue";
import { addContactUsMessage } from "../firebase";
import { flattie } from "flattie";

import uaParser from "ua-parser-js";
import { nanoid } from "nanoid";

var parser = new uaParser();
export default {
  name: "landing-page",
  components: {
    // NavbarComponent,
    // FooterComponent,
  },
  data() {
    return {
      mailSuccess: null,
      mailError: "",
      mailErrorObj: null,
      message: "Hello! I'm interested in my career development.",
      name: "",
      email: "",
      phone: "",
      successTimeOutIds: [],
      loadedId: nanoid(),
    };
  },
  methods: {
    getMessage: function () {
      return flattie({
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message,
        source: location.href,
        loadedId: this.loadedId,
        // navigator: navigator.userAgent,
        // navdata: uaParser(navigator.userAgent),
        _brwsr: parser.getResult(),
      });
    },
    clearForm: function () {
      // this.mailSuccess = null;

      this.mailError = "";
      this.mailErrorObj = null;
      this.message = "Hello! I'm interested in my career development.";
      this.name = "";
      this.email = "";
      this.phone = "";
    },
    setSuccessMessage: function () {
      this.mailSuccess = "Your message has been sent successfully.";
      this.successTimeOutIds.forEach((id) => {
        window.clearTimeout(id);
      });
      var TimeOutIds = window.setTimeout(() => {
        this.mailSuccess = "";
      }, 5000);
      this.successTimeOutIds.push(TimeOutIds);
    },
    sendMessage: async function () {
      // await addContactUsMessage(this.getMessage());
      this.mailError = "";
      this.mailErrorObj = null;
      var response = await fetch("https://freehitz2.tests.codehat.co.in/api/send-mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.getMessage()),
      });
      // console.log(response)
      if (!response.ok) {
        this.mailError = "Something went wrong. Please try again later.";
        this.mailErrorObj = await response.json();
        console.log(this.mailErrorObj, this.mailError);
        return;
      } else {
        await addContactUsMessage(this.getMessage());
        this.setSuccessMessage();
        this.clearForm();
      }

      // try {
      //     // .then((response) => response.json())
      //     // .then((data) => {
      //     //   console.log(data);
      //     // })
      //     // .catch((err) => {
      //     //   // console.log(err);
      //     //   this.mailError = "Error sending mail";
      //     //   this.mailErrorObj = err;
      //     //   console.log(this);
      //     // });
      // } catch (error) {
      //   console.log(error, "error");
      // }
      // alert(JSON.stringify({ message:this.message, name:this.name, email:this.email, phone:this.phone }, null, 2));
    },
  },
};
</script>

<style scoped>
.bg-main-123 {
  background-image: url(./../img/grid.svg);
}
</style>
